<template>
  <div class="container">
    <PublicHeader :totalName="totalName" />
    <div class="info">
      <div class="top">
        <div>{{ info.ProductName }}</div>
      </div>
      <div class="bottom">
        <div class="mItem">
          <p>{{ $t('property').available }}</p>
          <p>{{ info.Balance }}</p>
        </div>
        <div class="mItem">
          <p>{{ $t('property').OnOrders }}</p>
          <p>{{ info.Frozen }}</p>
        </div>
        <div class="mItem">
          <p>{{ $t('property').Estimated }}($)</p>
          <p>{{ info.Convert }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        {{ $t('property').cw }}
      </div>
      <div class="head">
        <div>{{ $t('property').number }}</div>
        <div>{{ $t('property').record }}</div>
        <div>{{ $t('property').time }}</div>
      </div>
      <div class="scroll">
        <NoData v-if="list.length === 0" />
        <van-list
          class="list-box"
          :class="{ more: totalName === $t('property').mhyzh }"
          ref="list"
          v-model="loading"
          :finished="finished"
          loading-text=""
          finished-text=""
          @load="getList"
        >
          <div class="item" v-for="(item, index) in list" :key="index">
            <div>{{ item.Amount }}</div>
            <div>{{ $t('ChangeType')[item.ChangeType] }}</div>
            <div>{{ item.CreateTime | getLocalTime }}</div>
          </div>
        </van-list>
      </div>
    </div>
    <div
      class="tab"
      v-if="totalName === $t('property').mhyzh"
      @click="
        $router.push({
          name: 'Transfer',
          query: {
            type: info.ProductName
          }
        })
      "
    >
      <img src="@/assets/new/huazhuan.png" alt="" />
      <div>
        {{ $t('property').Transfer }}
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import NoData from '@/components/noData'
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    PublicHeader,
    NoData
  },
  data() {
    return {
      totalName: '',
      list: [],
      form: {
        page: 1,
        size: 10,
        type: null,
        productId: null
      },
      info: {},
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.info = JSON.parse(this.$route.query.info)
    this.totalName =
      this.$route.query.type == 0
        ? this.$t('property').bbzh
        : this.$t('property').mhyzh
    this.form.type = this.$route.query.ProductName
    this.form.productId = this.$route.query.ProductId
  },
  methods: {
    async getList() {
      let res
      if (this.$route.query.type == 1) {
        res = await userApi.legalBalanceChange(this.form)
      } else {
        res = await userApi.coinBalanceChange(this.form)
      }

      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 44px 0 0;
  color: #9fbedc;
  .info {
    background: #17181e;
    padding: 20px 20px 15px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;
      color: #e143ac;
    }
    .bottom {
      display: flex;
      .mItem {
        flex: 1;
        p:nth-of-type(1) {
          margin: 18px 0 7px;
        }
        &:nth-of-type(2) {
          text-align: center;
        }
        &:nth-of-type(3) {
          text-align: right;
        }
      }
    }
  }
  .content {
    height: calc(100vh - 156px);
    overflow: hidden;
    margin-top: 10px;
    background: #17181e;
    padding: 10px 10px 0;
    .title {
      font-size: 16px;
      margin: 10px 0;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      border-bottom: 1px solid #101d2a;
    }
  }
  .tab {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 68px;
    border-top: 1px solid #15293d;
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }
  }
}
.list-box {
  height: calc(100vh - 240px);
  overflow: scroll;
}
.more {
  height: calc(100vh - 308px);
}
.item {
  background: #17181e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #1f253c;
  div {
    &:nth-of-type(1) {
      word-break: break-all;
      width: 100px;
    }
    &:nth-of-type(3) {
      width: 100px;
      text-align: right;
    }
    &:nth-of-type(2) {
      flex: 1;
    }
  }
}
</style>
